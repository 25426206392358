* {
    font-family: Raleway, Montserrat, Open Sans, sans-serif !important;
}

body {
    background-color: rgb(246, 246, 246);
}

.topo {
    background: linear-gradient(0deg, rgba(75, 73, 74, 0.2), rgba(75, 73, 74, 0.2)), url('https://images.unsplash.com/photo-1578916171728-46686eac8d58?crop=entropy&cs=tinysrgb&fm=jpg&ixid=Mnw2MzM0OHwwfDF8c2VhcmNofDF8fG1lcmNhZG98ZW58MHx8fHwxNjY2MzQyMTQz&ixlib=rb-4.0.3&q=80&w=1900&h=1080');
    min-height: 600px;
    background-size: cover;
    background-position-y: -200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topo-box {
    min-width: 50vw;
    min-height: 20vh;
    background: rgba(213, 143, 61, 0.35);
    box-shadow: 0 8px 32px 0 rgba(213, 143, 61, 0.37);
    backdrop-filter: blur(13.5px);
    -webkit-backdrop-filter: blur(13.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}

.rdt_TableHeadRow {
    font-family: Raleway, Montserrat, Open Sans, sans-serif !important;
    font-weight: bold;
    font-size: 14pt;
    height: 60px;
}

.rdt_TableCell {
    font-size: 11pt;
}

.rdt_TableRow {
    height: 100px;
}

.topo-box h1 {
    font-weight: 200;
    font-size: 18pt;
    color: rgb(255, 255, 255);
}

.topo-box-logo {
    padding: 20px;
}

.topo-box-nome-cliente {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.topo-divisor {
    min-height: 2px;
    background-color: white;
    width: 90%;
}

.topo-divisor-investimento {
    min-height: 1px;
    background-color: black;
    width: 100%;
}

.intro {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 7vh;
}

.intro h2 {
    font-style: italic;
    letter-spacing: .1rem;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 1.5px;
    margin-bottom: 80px;
}

.intro p {
    font-weight: lighter;
    line-height: 33px;
    letter-spacing: 1.5px;
    font-size: 18px;
    text-align: justify;
}

.intro-footer {
    padding: 50px;
    display: flex;
    padding-right: 10vw;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: bold;
    font-size: 18px;
}

.desafio {
    background-color: rgb(213, 143, 61);
    min-height: 600px;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 7vh;
}

.desafio h2 {
    color: white;
    letter-spacing: .1rem;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 1.5px;
    margin-bottom: 80px;
}

.desafio p {
    font-weight: lighter;
    line-height: 28.8px;
    letter-spacing: 0.5px;
    font-size: 18px;
    text-align: justify;
    color: white;
}

.solucao {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 7vh;
}

.solucao h2 {
    color: rgb(0, 4, 15);
    letter-spacing: .1rem;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 1.5px;
    margin-bottom: 80px;
}

.solucao p {
    font-weight: lighter;
    line-height: 33px;
    letter-spacing: 1.5px;
    font-size: 18px;

}

.solucao li {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: normal;
    line-height: 45px;
}

.banner {
    background: linear-gradient(0deg, rgba(235, 182, 84, 0.7), rgba(235, 182, 84, 0.7)), url('./banner.jpg');
    min-height: 200px;
    background-size: cover;
    background-position-y: -200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner h1 {
    font-weight: 200;
    color: rgb(255, 255, 255);
    font-style: italic;
}

.investimento {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 7vh;

}

.investimento h2 {
    color: rgb(0, 4, 15);
    letter-spacing: .1rem;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 1.5px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.investimento p {
    font-weight: lighter;
    line-height: 33px;
    letter-spacing: 1.5px;
    font-size: 16px;
}

.investimento li {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: normal;
    line-height: 45px;
}

.footer {
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 7vh;
    padding-bottom: 7vh;
}

.footer-item {
    color: white;
    min-width: 50%;
}

.modal-50 {
    min-width: 30vw;
}


@media only screen and (max-width: 900px) {

    .topo {
        background-repeat: no-repeat;
    }

    .topo-box {
        margin-top: -150px;
    }

    .banner h1 {
        font-weight: 200;
        font-size: 18px;
        color: rgb(255, 255, 255);
        font-style: italic;
    }

    .footer {
        flex-direction: column;
    }

    .footer-item {
        margin-bottom: 30px;
    }

    .intro {
        margin-top: -150px;
    }
}